.cl-rootBox {
  margin-top: 2.5rem; 

  @media (min-width: 640px) { 
    width: 100%; 
    max-width: 480px;
   }
}

.cl-card {
  padding-left: 1.5rem;
  padding-right: 1.5rem; 
  padding-top: 3rem;
  padding-bottom: 3rem; 
  gap: 0; 
  background-color: #ffffff; 
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); 
  width: inherit;

  @media (min-width: 640px) { 
    padding-left: 3rem;
    padding-right: 3rem; 
    border-radius: 0.5rem; 
   }
}

.cl-header {
  display: none;
}

.cl-logoBox {
  margin-bottom: .5rem;
}

.cl-main {
  margin-top: .5rem; 
}
.cl-formFieldInput {
  display: block; 
  padding-top: 0.375rem;
  padding-bottom: 0.375rem; 
  border-radius: 0.375rem; 
  border-width: 1px; 
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color); 
  --tw-ring-inset: inset; 
  --ring-color: #D1D5DB; 
  width: 100%; 
  color: #111827; 
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); 

  @media (min-width: 640px) { 
    font-size: 0.875rem;
    line-height: 1.25rem; 
    line-height: 1.5rem; 
   }
}

.cl-formFieldLabel {
  display: block; 
  margin-bottom: 0.5rem; 
  margin-top: 0.75rem; 
  font-size: 0.875rem;
  line-height: 1.25rem; 
  font-weight: 500; 
  line-height: 1.5rem; 
  color: #111827; 
}

.cl-formButtonPrimary {
  display: flex; 
  padding-top: 0.375rem;
  padding-bottom: 0.375rem; 
  padding-left: 0.75rem;
  padding-right: 0.75rem; 
  margin-top: 1.25rem; 
  justify-content: center; 
  border-radius: 0.375rem; 
  width: 100%; 
  font-size: 0.875rem;
  line-height: 1.25rem; 
  font-weight: 600; 
  line-height: 1.5rem; 
  color: #ffffff; 
  background-color: #1E40AF; 
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); 

  :hover {
     background-color: #1D4ED8; 
  }
}
.cl-footerAction__signIn {
  display: none;
}
.cl-footerAction__alternativeMethods {
  padding-top: 0.5rem; 
}
