/* 
border: 1px dashed rgba(140,140,140,0.8);
    border-radius: 3px;
 */

.ql-toolbar {
  border-top: 1px solid rgba(140,140,140,0.8) !important;
  border-left: 1px solid rgba(140,140,140,0.8) !important;
  border-right: 1px solid rgba(140,140,140,0.8) !important;
  border-bottom: 1px dashed rgba(140,140,140,0.8) !important;
  border-radius: 3px;
  background-color: rgb(225, 226, 225);
}

.ql-container {
  border-top: 0px solid rgba(140,140,140,0.8) !important;
  border-left: 1px solid rgba(140,140,140,0.8) !important;
  border-right: 1px solid rgba(140,140,140,0.8) !important;
  border-bottom: 1px solid rgba(140,140,140,0.8) !important;
  border-radius: 3px;
}

.ql-editor {
  color: rgb(46, 46, 46);
  font-size: 16px;
  font-family: 'Roboto';
  min-height: 150px;
}